import { useState } from "react";
import {
  Checkbox,
  Container,
  Flex,
  FormikField,
  Link,
  PrimaryButton,
  Text,
} from "flicket-ui";
import { Formik, FormikHelpers, FormikValues } from "formik";
import { useRouter } from "next/router";
import { Modal, QuestionMark } from "~components/common";
import { getFormValues, validationSchema } from "~form/signup";

type SignupFormProps = {
  handleSubmit: (
    values: FormikValues,
    actions: FormikHelpers<FormikValues>
  ) => void;
};

export const SignupForm = ({ handleSubmit }: SignupFormProps) => {
  const router = useRouter();
  const [isChecked, setIsChecked] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);

  function handleCheck() {
    setIsChecked(!isChecked);
  }

  return (
    <>
      <Formik
        initialValues={getFormValues({
          email: router?.query?.email as string,
          firstName: router?.query?.lastName as string,
          lastName: router?.query?.lastName as string,
        })}
        validationSchema={validationSchema}
        onSubmit={(values, actions) => {
          handleSubmit(values, actions);
        }}
      >
        {({ handleSubmit, isSubmitting, submitCount }) => (
          <Flex
            as="form"
            onSubmit={handleSubmit}
            maxWidth={{ default: "100%", sm: 380 }}
            flexDirection="column"
            mb="1/2"
            role="form"
          >
            <FormikField
              label="First name"
              name="firstName"
              type="text"
              mb={3}
              submitCount={submitCount}
            />
            <FormikField
              label="Last name"
              name="lastName"
              type="text"
              mb={3}
              submitCount={submitCount}
            />
            <FormikField
              label="E-mail"
              name="email"
              type="email"
              mb={3}
              submitCount={submitCount}
            />
            <FormikField
              label="Password"
              name="password"
              type="password"
              mb={2}
              submitCount={submitCount}
            />
            <FormikField
              name="enableTwoFactorAuthentication"
              as={Checkbox}
              fieldProps={{
                customLabel: () => (
                  <>
                    <Text ml={1} as="label" variant="label.S" paddingRight={1}>
                      Enable Two-factor Authentication
                    </Text>
                    <QuestionMark onClick={() => setIsModalOpen(true)} />
                  </>
                ),
                mb: 4,
                mt: 1,
                color: "N800",
              }}
            />
            <FormikField
              name="terms"
              as={Checkbox}
              onChange={() => handleCheck()}
              fieldProps={{
                customLabel: () => (
                  <Text ml={1} as="label" variant="label.S" htmlFor="terms">
                    I agree with the&nbsp;
                    <Link
                      color="P300"
                      to="/terms-and-conditions"
                      target="_blank"
                    >
                      Terms and Conditions
                    </Link>
                  </Text>
                ),
                mb: 4,
                mt: 1,
                color: "N800",
              }}
            />

            <PrimaryButton
              type="submit"
              isLoading={isSubmitting}
              width="100%"
              lineHeight="normal"
              boxShadow="button"
              disabled={!isChecked}
            >
              Create account
            </PrimaryButton>
          </Flex>
        )}
      </Formik>
      <TwoFactorAuthenticationInfoModal
        open={isModalOpen}
        onClose={() => setIsModalOpen(false)}
      />
    </>
  );
};

interface TwoFactorAuthenticationInfoModalProps {
  open: boolean;
  onClose: () => void;
}

const TwoFactorAuthenticationInfoModal = ({
  open,
  onClose,
}: TwoFactorAuthenticationInfoModalProps) => {
  return (
    <Modal
      isOpen={open}
      onClose={() => onClose()}
      height="auto"
      bg="transparent"
    >
      <Container
        px={2}
        innerProps={{
          w: "100%",
          maxW: "560px",
          mx: "auto" as any,
          p: { _: 2, md: 4 },
        }}
      >
        <Text
          fontSize={6}
          fontWeight="heavy"
          lineHeight="normal"
          color="N800"
          mb={4}
        >
          Two-factor authentication
        </Text>
        <Text fontSize={4} color="N800" mb={1}>
          Two-factor authentication is a way to add extra security to your
          account. It requires you to enter a code from your phone from either a
          text message or authenticator app.
        </Text>
        <Flex flexDir={"row-reverse"} alignContent={"flex-end"} maxWidth="full">
          <PrimaryButton marginLeft={2} onClick={onClose} autoFocus>
            Got it
          </PrimaryButton>
        </Flex>
      </Container>
    </Modal>
  );
};
